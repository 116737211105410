/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const createCustomerMapping = /* GraphQL */ `mutation CreateCustomerMapping(
  $input: CreateCustomerMappingInput!
  $condition: ModelCustomerMappingConditionInput
) {
  createCustomerMapping(input: $input, condition: $condition) {
    id
    plant
    author
    customerId
    customerName
    fileName
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCustomerMappingMutationVariables,
  APITypes.CreateCustomerMappingMutation
>;
export const updateCustomerMapping = /* GraphQL */ `mutation UpdateCustomerMapping(
  $input: UpdateCustomerMappingInput!
  $condition: ModelCustomerMappingConditionInput
) {
  updateCustomerMapping(input: $input, condition: $condition) {
    id
    plant
    author
    customerId
    customerName
    fileName
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCustomerMappingMutationVariables,
  APITypes.UpdateCustomerMappingMutation
>;
export const deleteCustomerMapping = /* GraphQL */ `mutation DeleteCustomerMapping(
  $input: DeleteCustomerMappingInput!
  $condition: ModelCustomerMappingConditionInput
) {
  deleteCustomerMapping(input: $input, condition: $condition) {
    id
    plant
    author
    customerId
    customerName
    fileName
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCustomerMappingMutationVariables,
  APITypes.DeleteCustomerMappingMutation
>;
