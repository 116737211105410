import React from 'react';
import CustomerDetailsForm from './CustomerDetailsForm';

interface Props {
}

interface AddCustomerDetailsState {
};

class AddCustomerDetails extends React.Component<Props, AddCustomerDetailsState> {

  constructor(props: Props) {
    super(props);
  }


  render() {
    return (
      <div>
        <h2>Add Customer Details</h2>
        <CustomerDetailsForm submitLabel='Add details'/>
      </div>
    );
  }
}

export default AddCustomerDetails;
