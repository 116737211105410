/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getCustomerMapping = /* GraphQL */ `query GetCustomerMapping($id: ID!) {
  getCustomerMapping(id: $id) {
    id
    plant
    author
    customerId
    customerName
    fileName
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCustomerMappingQueryVariables,
  APITypes.GetCustomerMappingQuery
>;
export const listCustomerMappings = /* GraphQL */ `query ListCustomerMappings(
  $filter: ModelCustomerMappingFilterInput
  $limit: Int
  $nextToken: String
) {
  listCustomerMappings(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      plant
      author
      customerId
      customerName
      fileName
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCustomerMappingsQueryVariables,
  APITypes.ListCustomerMappingsQuery
>;
export const byPlantAndCustomerId = /* GraphQL */ `query ByPlantAndCustomerId(
  $plant: String!
  $customerId: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelCustomerMappingFilterInput
  $limit: Int
  $nextToken: String
) {
  byPlantAndCustomerId(
    plant: $plant
    customerId: $customerId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      plant
      author
      customerId
      customerName
      fileName
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ByPlantAndCustomerIdQueryVariables,
  APITypes.ByPlantAndCustomerIdQuery
>;
