import { Authenticator } from '@aws-amplify/ui-react';
import { Button, Layout, Menu } from 'antd';
import Amplify from 'aws-amplify';
import { useState } from 'react';
import awsconfig from './aws-exports';
import AddCustomerDetails from './components/AddCustomerDetails';
import ViewCustomerDetails from './components/ViewCustomerDetails';

Amplify.configure(awsconfig);

const { Header, Content, Footer } = Layout;
const { Item } = Menu;

function App() {
  const [currentPage, setCurrentPage] = useState('1');
  return (
    <Authenticator
      hideSignUp={true}
    >
      {({ signOut }) => (
        <Layout>
          <Header>
            <Menu theme="dark" mode="horizontal" defaultSelectedKeys={ ['1'] } style={{ justifyContent: 'flex-end' }}>
              <Item key="1" onClick={() => setCurrentPage('1')}>
                View
              </Item>
              <Item key="2" onClick={() => setCurrentPage('2')}>
                Add
              </Item>
              <Item key="3">
                <Button type="primary" onClick={signOut}>Sign Out</Button>
              </Item>
            </Menu>
          </Header>
          <Content style={{ padding: '0 50px', minHeight: '100vh' }}>
            <div style={{ background: '#fff', padding: 24 }}>
              {currentPage === '1' ? <ViewCustomerDetails/> : <AddCustomerDetails/>}
            </div>
          </Content>
          <Footer style={{ textAlign: 'center' }}>Nexineer GmbH ©{new Date().getFullYear().toString()}</Footer>
        </Layout>
      )}
    </Authenticator>
  );
}

export default App;
