import { QuestionCircleOutlined } from '@ant-design/icons';
import { GraphQLResult } from '@aws-amplify/api';
import { message, Modal, Popconfirm, Table } from 'antd';
import { API, Auth, Storage } from 'aws-amplify';
import React from 'react';
import { deleteCustomerMapping } from '../graphql/mutations';
import { byPlantAndCustomerId } from '../graphql/queries';
import CustomerDetailsForm from './CustomerDetailsForm';

interface ViewCustomerDetailsState {
  modalVisible: boolean;
  data: any[];
  plant: string;
  selectedCustomerMappingId?: string;
}

interface ViewCustomerDetailsProps {
};

class ViewCustomerDetails extends React.Component<ViewCustomerDetailsProps, ViewCustomerDetailsState> {
  fileInput: any;

  constructor(props: ViewCustomerDetailsProps) {
    super(props);
    this.state = {
      modalVisible: false,
      data: [],
      plant: '',
    };
    this.fileInput = React.createRef();
  }

  componentDidMount() {
    Auth.currentSession()
      .then(async session => {
        const plant = (session as any).accessToken.payload['cognito:groups'][0];
        this.setState({ plant: plant });
        await this.reloadList();
      })
      .catch(async err => {
        console.log(err);
        await message.error(err);
      });
  }

  async reloadList() {
    let first = true;
    let token = null;
    const items: any[] = [];

    while (first || token != null) {
      if (first) {
        first = false;
      }
      const result = await API.graphql({
        query: byPlantAndCustomerId,
        variables: {
          // limit: 1,
          plant: this.state.plant,
          nextToken: token,
        },
      }) as GraphQLResult<any>;

      items.push(...result.data.byPlantAndCustomerId.items);
      token = result.data.byPlantAndCustomerId.nextToken;
    }

    this.setState({ data: items });
  }

  delete = async (id: any) => {
    try {
      await API.graphql({
        query: deleteCustomerMapping,
        variables: {
          input: {
            id: id,
          },
        },
      });

      await this.reloadList();

      await message.success('Customer details deleted successfully');
    } catch (err) {
      if (err instanceof Error) {
        await message.error(err);
      }
    }
  };

  reset = () => {
    this.fileInput.current.value = '';
  };

  hideModal = () => {
    this.setState({ modalVisible: false });
  };

  download = async (file: string) => {
    try {
      const response = await Storage.get(`${this.state.plant}/${file}`);
      window.open(response, '_blank');
    } catch (err) {
      await message.error(err + ': Error download the file');
    }
  };

  async selectCustomerMapping(id: string) {
    await this.setState({ selectedCustomerMappingId: id, modalVisible: true });
  }

  render() {
    const columns = [
      {
        title: 'Customer ID',
        dataIndex: 'customerId',
        key: 'customerId',
        render: (text: any) => <span>{text}</span>,
      },
      {
        title: 'Customer Name',
        dataIndex: 'customerName',
        key: 'customerName',
      },
      {
        title: 'FileName',
        dataIndex: 'fileName',
        key: 'fileName',
        render: (text: string) => <a href={'#' + text} onClick={() => this.download(text)}>{text}</a>,
      },
      {
        title: 'Action',
        dataIndex: 'action',
        key: 'action',
        render: (text: any, record: any) => (
          <div>
            <p>{text}</p>
            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100px' }}>
              <span className="txt" onClick={() => this.selectCustomerMapping(record.key)}>Edit</span>
              <Popconfirm
                title="Are you sure？"
                icon={<QuestionCircleOutlined style={{ color: 'red' }}/>}
                onConfirm={() => this.delete(record.key)}
                okButtonProps={{ loading: false, danger: true }}
              >
                <span className="txt">Delete</span>
              </Popconfirm>
            </div>
          </div>
        ),
      },
    ];

    const data = this.state.data.map((item: any) => {
      return {
        key: item.id,
        customerId: item.customerId,
        customerName: item.customerName,
        fileName: item.fileName,
      };
    });

    return (
      <div>
        <h1>View Customer Details</h1>
        <Modal title="Edit Customer Details" visible={this.state.modalVisible} footer={null} width="70%"
          onCancel={this.hideModal}>
          <CustomerDetailsForm submitLabel='Change details' customerMappingId={this.state.selectedCustomerMappingId}
            onSave={this.hideModal}/>
        </Modal>
        <Table columns={columns} dataSource={data}
          pagination={{ pageSize: 1000, pageSizeOptions: ['10', '20', '50', '100', '500', '1000'] }}/>
      </div>
    );
  }
}

export default ViewCustomerDetails;
