import { GraphQLResult } from '@aws-amplify/api';
import { Button, Form, Input, message, Select } from 'antd';
import { API, Auth, Storage } from 'aws-amplify';
import React, { ChangeEvent } from 'react';
import { createCustomerMapping, updateCustomerMapping } from '../graphql/mutations';
import { getCustomerMapping } from '../graphql/queries';

interface CustomerDetailsFormProps {
  customerMappingId?: string;

  submitLabel: string;

  onSave?: () => void;
}

interface CustomerDetailsFormState {
  id: string;
  customerId: string;
  customerName: string;
  fileName: string;
  plant: string;
  author: string;
  user: string;
  fileList: string[];
  session: any;
};

const { Option } = Select;

class CustomerDetailsForm extends React.Component<CustomerDetailsFormProps, CustomerDetailsFormState> {
  fileInput: any;

  constructor(props: CustomerDetailsFormProps) {
    super(props);
    this.state = {
      id: '',
      customerId: '',
      customerName: '',
      fileName: '',
      plant: '',
      author: '',
      user: '',
      fileList: [],
      session: '',
    };
    this.fileInput = React.createRef();
  }

  async componentDidMount() {
    try {
      const userinfo = await Auth.currentUserInfo();
      const user = (userinfo as any).attributes.email;
      this.setState({ user: user });

      const session = await Auth.currentSession();
      const plant = (session as any).accessToken.payload['cognito:groups'][0];
      this.setState({ session: session, plant: plant });

      const list = await Storage.list(plant);
      const fileList = list.map(entry => {
        const value = entry.key?.slice(plant.length + 1);
        if (value == undefined) {
          return '';
        }
        return value;
      });
      this.setState({ fileList: fileList });
    } catch (err) {
      if (err instanceof Error) {
        await message.error(err.toString());
      } else {
        console.log(err);
      }
    }
  }

  async componentDidUpdate(prevProps: Readonly<CustomerDetailsFormProps>) {
    if (this.props.customerMappingId && (this.state.id === '' ||
      this.props.customerMappingId != prevProps.customerMappingId)) {
      const result = (await API.graphql({
        query: getCustomerMapping,
        variables: {
          id: this.props.customerMappingId,
        },
      })) as GraphQLResult<any>;

      const customerMapping = result.data.getCustomerMapping;
      await this.setState({
        id: customerMapping.id,
        customerId: customerMapping.customerId,
        customerName: customerMapping.customerName,
        fileName: customerMapping.fileName,
        author: customerMapping.author,
        plant: customerMapping.plant,
      });
    }
  }

  handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    switch (event.target.name) {
      case 'customerId' :
        this.setState({ customerId: event.target.value });
        break;
      case 'customerName':
        this.setState({ customerName: event.target.value });
    }
  };

  handleSubmit = async (event: React.MouseEvent<any>) => {
    event.preventDefault();
    try {
      let fileName = this.state.fileName;
      if (this.fileInput.current && this.fileInput.current.files && this.fileInput.current.files.length) {
        if (!await this.checkJPG(this.fileInput.current.files[0])) {
          return;
        }

        await Storage.put(`${this.state.plant}/${this.fileInput.current.files[0].name}`, this.fileInput.current.files[0]);
        fileName = this.fileInput.current.files[0].name;
      }

      if (this.state.id === '') {
        await API.graphql({
          query: createCustomerMapping,
          variables: {
            input: {
              plant: this.state.plant,
              fileName: fileName,
              customerId: this.state.customerId.replace(/^0+/, ''),
              author: this.state.user,
              customerName: this.state.customerName,
            },
          },
        });
      } else {
        await API.graphql({
          query: updateCustomerMapping,
          variables: {
            input: {
              id: this.state.id,
              plant: this.state.plant,
              fileName: fileName,
              customerId: this.state.customerId.replace(/^0+/, ''),
              author: this.state.user,
              customerName: this.state.customerName,
            },
          },
        });
      }
      await message.success('Customer details updated successfully');

      if (this.props.onSave) {
        this.props.onSave();
      }
    } catch (e) {
      console.log(e);
      await message.error('Error creating customer.');
    }
  };

  checkJPG = async (file: any) => {
    if (file.type === 'image/jpeg' || file.type === 'image/jpg') {
      return true;
    }
    await message.error('Only JPG files are allowed to be uploaded');
    return false;
  };

  render() {
    return (
      <div>
        <Form
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 16 }}
        >
          <Form.Item label="Customer ID">
            <Input name="customerId" value={this.state.customerId} onChange={this.handleChange}/>
          </Form.Item>
          <Form.Item label="Customer Name in SAP">
            <Input name="customerName" value={this.state.customerName} onChange={this.handleChange}/>
          </Form.Item>
          <Form.Item label="Select Existing File">
            <Select allowClear={true} value={this.state.fileName}
              onChange={(val: any) => this.setState({ fileName: val })}>
              {this.state.fileList.map((fileName: string, index: number) => {
                return <Option key={index} value={fileName}>{fileName}</Option>;
              })}
            </Select>
          </Form.Item>
          <Form.Item label="Upload New JPG File">
            <input
              onChange={() => (this.fileInput.current && this.fileInput.current.files && this.fileInput.current.files.length) ? this.checkJPG(this.fileInput.current.files[0]) : ''}
              type="file" ref={this.fileInput}/>
          </Form.Item>
          <Form.Item label={this.props.submitLabel}>
            <Button type="primary" htmlType="submit"
              onClick={(event: React.MouseEvent<any>) => this.handleSubmit(event)}>Submit</Button>
          </Form.Item>
        </Form>
      </div>
    );
  }
}

export default CustomerDetailsForm;
